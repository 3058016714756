import React from "react";

function Logo(props) {
  return (
    <svg viewBox="0 0 214.107 239.517" {...props}>
      xmlns="http://www.w3.org/2000/svg"
      <path
        id="_2WF"
        d="M2136.781,198.7c-8.328,3.383-14.832,9.143-14.832,19.409a47.142,47.142,0,0,0,.462,4.7c3.235,9.74,8.913,16.719,18.931,16.749,1.99-.376,6.213-1.157,7.9-2.244,6.551-4.231,11.115-10.1,11.136-18.625a48.414,48.414,0,0,0-1.487-6.674c-3.441-8.232-8.815-13.976-17.489-13.976A24.174,24.174,0,0,0,2136.781,198.7Zm-20.8-50.053c-8.328,3.383-14.831,9.143-14.831,19.409a47.206,47.206,0,0,0,.46,4.7c3.234,9.74,8.915,16.719,18.931,16.749,1.99-.376,6.213-1.157,7.9-2.244,6.552-4.231,11.117-10.1,11.138-18.625a48.487,48.487,0,0,0-1.488-6.674c-3.442-8.231-8.814-13.976-17.488-13.976A24.2,24.2,0,0,0,2115.98,148.645ZM2020.1,198.7c-8.327,3.383-14.831,9.143-14.831,19.409a47.174,47.174,0,0,0,.461,4.7c3.235,9.74,8.915,16.719,18.931,16.749,1.991-.376,6.213-1.157,7.9-2.244,6.551-4.231,11.115-10.1,11.136-18.625a48.094,48.094,0,0,0-1.487-6.674c-3.441-8.232-8.814-13.976-17.489-13.976A24.184,24.184,0,0,0,2020.1,198.7Zm27.057-67.4c-9.138,4.365-13.362,16.154-9.34,26.069l28.561,70.392c4.022,9.913,14.888,14.495,24.028,10.132s13.362-16.154,9.339-26.069l-28.56-70.392c-4.024-9.913-14.89-14.5-24.03-10.132Zm-9.23-122.69c-2.148,4.564-4.184,9.247-6.248,13.856q-7.639,17.063-15.235,34.148-28.678,64.4-57.233,128.885c-2.58,5.531-12.855,27.316-12.942,33.4l1.082,5.588c3.36,8.644,8.792,15.078,17.894,15.078,5.838,0,10.623-3.035,14.633-7.468.777-1.269,1.492-2.82,2.145-4.164,1.419-2.918,2.792-5.868,4.155-8.82,4.8-10.378,9.478-20.828,14.144-31.275q26.08-58.38,51.611-117.064l.195-.441h1.575l.212.347a111.639,111.639,0,0,1,5.591,10.739c5.887,12.469,11.191,25.492,16.338,38.335,3.838,9.574,9.752,22.1,20.888,22.1a10.8,10.8,0,0,0,3.865-.309c8.829-2.96,15.429-8.83,15.429-19.417V120.4a20.827,20.827,0,0,0-.565-4.207,49.2,49.2,0,0,0-2.387-5.413q-3.567-7.691-7.08-15.4c-10.393-22.948-20.251-46.215-29.874-69.553-3.434-8.329-8.97-21.33-17.446-24.768A14.093,14.093,0,0,0,2053.325.04c-6.172,0-11.32,3.856-15.394,8.575Z"
        transform="translate(-1946.271 -0.04)"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Logo;
